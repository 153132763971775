*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

.tw-invisible {
  visibility: hidden
}

.tw-fixed {
  position: fixed
}

.\!tw-relative {
  position: relative !important
}

.\!tw-left-0 {
  left: 0px !important
}

.\!tw-top-0 {
  top: 0px !important
}

.tw-bottom-20 {
  bottom: 5rem
}

.tw-left-1\/2 {
  left: 50%
}

.tw-left-\[13\%\] {
  left: 13%
}

.tw-right-\[14\%\] {
  right: 14%
}

.tw-top-\[190px\] {
  top: 190px
}

.tw-z-50 {
  z-index: 50
}

.tw-z-\[9999\] {
  z-index: 9999
}

.tw-float-right {
  float: right
}

.\!tw-m-0 {
  margin: 0px !important
}

.tw-m-0 {
  margin: 0px
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto
}

.tw-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.tw-my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.tw-my-auto {
  margin-top: auto;
  margin-bottom: auto
}

.\!tw-mb-0 {
  margin-bottom: 0px !important
}

.tw-mb-2 {
  margin-bottom: 0.5rem
}

.tw-mb-3 {
  margin-bottom: 0.75rem
}

.tw-mb-4 {
  margin-bottom: 1rem
}

.tw-mb-5 {
  margin-bottom: 1.25rem
}

.tw-mb-\[6px\] {
  margin-bottom: 6px
}

.tw-mb-\[8px\] {
  margin-bottom: 8px
}

.tw-me-20 {
  -webkit-margin-end: 5rem;
          margin-inline-end: 5rem
}

.tw-ml-4 {
  margin-left: 1rem
}

.tw-mr-2 {
  margin-right: 0.5rem
}

.tw-mr-3 {
  margin-right: 0.75rem
}

.tw-mr-5 {
  margin-right: 1.25rem
}

.tw-ms-1 {
  -webkit-margin-start: 0.25rem;
          margin-inline-start: 0.25rem
}

.tw-ms-10 {
  -webkit-margin-start: 2.5rem;
          margin-inline-start: 2.5rem
}

.tw-ms-2 {
  -webkit-margin-start: 0.5rem;
          margin-inline-start: 0.5rem
}

.tw-ms-\[25px\] {
  -webkit-margin-start: 25px;
          margin-inline-start: 25px
}

.tw-ms-auto {
  -webkit-margin-start: auto;
          margin-inline-start: auto
}

.tw-mt-1 {
  margin-top: 0.25rem
}

.tw-mt-10 {
  margin-top: 2.5rem
}

.tw-mt-2 {
  margin-top: 0.5rem
}

.tw-mt-3 {
  margin-top: 0.75rem
}

.tw-mt-4 {
  margin-top: 1rem
}

.tw-mt-5 {
  margin-top: 1.25rem
}

.tw-mt-6 {
  margin-top: 1.5rem
}

.tw-mt-96 {
  margin-top: 24rem
}

.tw-mt-\[10px\] {
  margin-top: 10px
}

.tw-mt-\[12px\] {
  margin-top: 12px
}

.tw-mt-\[1px\] {
  margin-top: 1px
}

.tw-mt-\[2px\] {
  margin-top: 2px
}

.tw-mt-auto {
  margin-top: auto
}

.tw-line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2
}

.\!tw-block {
  display: block !important
}

.tw-block {
  display: block
}

.tw-flex {
  display: -webkit-flex;
  display: flex
}

.tw-grid {
  display: grid
}

.\!tw-hidden {
  display: none !important
}

.tw-hidden {
  display: none
}

.\!tw-size-1 {
  width: 0.25rem !important;
  height: 0.25rem !important
}

.tw-size-3\.5 {
  width: 0.875rem;
  height: 0.875rem
}

.tw-size-6 {
  width: 1.5rem;
  height: 1.5rem
}

.\!tw-h-\[180px\] {
  height: 180px !important
}

.\!tw-h-\[290px\] {
  height: 290px !important
}

.\!tw-h-\[35px\] {
  height: 35px !important
}

.\!tw-h-\[370px\] {
  height: 370px !important
}

.\!tw-h-\[37px\] {
  height: 37px !important
}

.\!tw-h-auto {
  height: auto !important
}

.tw-h-3 {
  height: 0.75rem
}

.tw-h-40 {
  height: 10rem
}

.tw-h-6 {
  height: 1.5rem
}

.tw-h-\[119px\] {
  height: 119px
}

.tw-h-\[132\.5px\] {
  height: 132.5px
}

.tw-h-\[156px\] {
  height: 156px
}

.tw-h-\[16px\] {
  height: 16px
}

.tw-h-\[18px\] {
  height: 18px
}

.tw-h-\[199\.5px\] {
  height: 199.5px
}

.tw-h-\[20px\] {
  height: 20px
}

.tw-h-\[21px\] {
  height: 21px
}

.tw-h-\[250px\] {
  height: 250px
}

.tw-h-\[28px\] {
  height: 28px
}

.tw-h-\[300px\] {
  height: 300px
}

.tw-h-\[30px\] {
  height: 30px
}

.tw-h-\[33px\] {
  height: 33px
}

.tw-h-\[35px\] {
  height: 35px
}

.tw-h-\[36px\] {
  height: 36px
}

.tw-h-\[38\.5px\] {
  height: 38.5px
}

.tw-h-\[39px\] {
  height: 39px
}

.tw-h-\[40px\] {
  height: 40px
}

.tw-h-\[42px\] {
  height: 42px
}

.tw-h-\[438px\] {
  height: 438px
}

.tw-h-\[500px\] {
  height: 500px
}

.tw-h-\[50px\] {
  height: 50px
}

.tw-h-\[609px\] {
  height: 609px
}

.tw-h-\[63px\] {
  height: 63px
}

.tw-h-\[69px\] {
  height: 69px
}

.tw-h-\[70vh\] {
  height: 70vh
}

.tw-h-\[76\.5px\] {
  height: 76.5px
}

.tw-h-\[83vh\] {
  height: 83vh
}

.tw-h-\[88px\] {
  height: 88px
}

.tw-h-\[calc\(100vh-15rem\)\] {
  height: calc(100vh - 15rem)
}

.tw-h-\[calc\(76vh-15rem\)\] {
  height: calc(76vh - 15rem)
}

.tw-h-\[calc\(82vh-110px\)\] {
  height: calc(82vh - 110px)
}

.tw-h-full {
  height: 100%
}

.tw-max-h-\[250px\] {
  max-height: 250px
}

.tw-max-h-\[39px\] {
  max-height: 39px
}

.tw-max-h-\[63px\] {
  max-height: 63px
}

.tw-max-h-\[calc\(75vh-20vh\)\] {
  max-height: calc(75vh - 20vh)
}

.tw-max-h-\[calc\(84vh-150px\)\] {
  max-height: calc(84vh - 150px)
}

.tw-min-h-\[231px\] {
  min-height: 231px
}

.tw-min-h-\[33px\] {
  min-height: 33px
}

.tw-min-h-\[45px\] {
  min-height: 45px
}

.\!tw-w-\[260px\] {
  width: 260px !important
}

.\!tw-w-\[350px\] {
  width: 350px !important
}

.\!tw-w-\[730px\] {
  width: 730px !important
}

.\!tw-w-\[750px\] {
  width: 750px !important
}

.\!tw-w-\[82px\] {
  width: 82px !important
}

.\!tw-w-\[97px\] {
  width: 97px !important
}

.\!tw-w-full {
  width: 100% !important
}

.tw-w-1 {
  width: 0.25rem
}

.tw-w-1\/12 {
  width: 8.333333%
}

.tw-w-1\/2 {
  width: 50%
}

.tw-w-1\/4 {
  width: 25%
}

.tw-w-16 {
  width: 4rem
}

.tw-w-2\/12 {
  width: 16.666667%
}

.tw-w-2\/4 {
  width: 50%
}

.tw-w-20 {
  width: 5rem
}

.tw-w-3\/4 {
  width: 75%
}

.tw-w-6 {
  width: 1.5rem
}

.tw-w-\[1\.2px\] {
  width: 1.2px
}

.tw-w-\[10\%\] {
  width: 10%
}

.tw-w-\[100px\] {
  width: 100px
}

.tw-w-\[106px\] {
  width: 106px
}

.tw-w-\[110px\] {
  width: 110px
}

.tw-w-\[112px\] {
  width: 112px
}

.tw-w-\[119px\] {
  width: 119px
}

.tw-w-\[12\%\] {
  width: 12%
}

.tw-w-\[125px\] {
  width: 125px
}

.tw-w-\[13\.2\%\] {
  width: 13.2%
}

.tw-w-\[13\.8\%\] {
  width: 13.8%
}

.tw-w-\[14\%\] {
  width: 14%
}

.tw-w-\[140px\] {
  width: 140px
}

.tw-w-\[15\%\] {
  width: 15%
}

.tw-w-\[159px\] {
  width: 159px
}

.tw-w-\[15px\] {
  width: 15px
}

.tw-w-\[162px\] {
  width: 162px
}

.tw-w-\[164px\] {
  width: 164px
}

.tw-w-\[168px\] {
  width: 168px
}

.tw-w-\[173px\] {
  width: 173px
}

.tw-w-\[177px\] {
  width: 177px
}

.tw-w-\[180px\] {
  width: 180px
}

.tw-w-\[20\.7\%\] {
  width: 20.7%
}

.tw-w-\[20\.8\%\] {
  width: 20.8%
}

.tw-w-\[200px\] {
  width: 200px
}

.tw-w-\[20px\] {
  width: 20px
}

.tw-w-\[21\.5\%\] {
  width: 21.5%
}

.tw-w-\[224px\] {
  width: 224px
}

.tw-w-\[240px\] {
  width: 240px
}

.tw-w-\[25\%\] {
  width: 25%
}

.tw-w-\[3\%\] {
  width: 3%
}

.tw-w-\[30px\] {
  width: 30px
}

.tw-w-\[35px\] {
  width: 35px
}

.tw-w-\[37\%\] {
  width: 37%
}

.tw-w-\[37\.3\%\] {
  width: 37.3%
}

.tw-w-\[37\.7\%\] {
  width: 37.7%
}

.tw-w-\[3px\] {
  width: 3px
}

.tw-w-\[40\%\] {
  width: 40%
}

.tw-w-\[400px\] {
  width: 400px
}

.tw-w-\[40px\] {
  width: 40px
}

.tw-w-\[435px\] {
  width: 435px
}

.tw-w-\[438px\] {
  width: 438px
}

.tw-w-\[441px\] {
  width: 441px
}

.tw-w-\[46\%\] {
  width: 46%
}

.tw-w-\[50\%\] {
  width: 50%
}

.tw-w-\[50px\] {
  width: 50px
}

.tw-w-\[55\.6\%\] {
  width: 55.6%
}

.tw-w-\[6\.9\%\] {
  width: 6.9%
}

.tw-w-\[63px\] {
  width: 63px
}

.tw-w-\[66px\] {
  width: 66px
}

.tw-w-\[680px\] {
  width: 680px
}

.tw-w-\[73\%\] {
  width: 73%
}

.tw-w-\[74px\] {
  width: 74px
}

.tw-w-\[78px\] {
  width: 78px
}

.tw-w-\[80px\] {
  width: 80px
}

.tw-w-\[82px\] {
  width: 82px
}

.tw-w-\[85\%\] {
  width: 85%
}

.tw-w-\[9\%\] {
  width: 9%
}

.tw-w-\[90px\] {
  width: 90px
}

.tw-w-\[91px\] {
  width: 91px
}

.tw-w-\[92px\] {
  width: 92px
}

.tw-w-\[93\%\] {
  width: 93%
}

.tw-w-\[95\%\] {
  width: 95%
}

.tw-w-\[97\%\] {
  width: 97%
}

.tw-w-\[98\%\] {
  width: 98%
}

.tw-w-\[99\%\] {
  width: 99%
}

.tw-w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}

.tw-w-full {
  width: 100%
}

.tw-min-w-16 {
  min-width: 4rem
}

.tw-min-w-\[343\.5px\] {
  min-width: 343.5px
}

.tw-min-w-\[85px\] {
  min-width: 85px
}

.tw-min-w-full {
  min-width: 100%
}

.tw-max-w-28 {
  max-width: 7rem
}

.tw-max-w-\[258px\] {
  max-width: 258px
}

.tw-max-w-\[350px\] {
  max-width: 350px
}

.tw-max-w-\[400px\] {
  max-width: 400px
}

.tw-max-w-full {
  max-width: 100%
}

.tw-flex-grow {
  -webkit-flex-grow: 1;
          flex-grow: 1
}

.tw-table-auto {
  table-layout: auto
}

.tw-border-collapse {
  border-collapse: collapse
}

.-tw-translate-x-1\/2 {
  --tw-translate-x: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.tw-translate-x-10 {
  --tw-translate-x: 2.5rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.tw-transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.\!tw-transform-none {
  -webkit-transform: none !important;
          transform: none !important
}

.tw-cursor-pointer {
  cursor: pointer
}

.tw-flex-row {
  -webkit-flex-direction: row;
          flex-direction: row
}

.tw-flex-col {
  -webkit-flex-direction: column;
          flex-direction: column
}

.tw-items-end {
  -webkit-align-items: flex-end;
          align-items: flex-end
}

.tw-items-center {
  -webkit-align-items: center;
          align-items: center
}

.\!tw-justify-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important
}

.tw-justify-start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start
}

.tw-justify-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end
}

.tw-justify-center {
  -webkit-justify-content: center;
          justify-content: center
}

.tw-justify-between {
  -webkit-justify-content: space-between;
          justify-content: space-between
}

.tw-gap-1 {
  gap: 0.25rem
}

.tw-gap-12 {
  gap: 3rem
}

.tw-gap-2 {
  gap: 0.5rem
}

.tw-gap-3 {
  gap: 0.75rem
}

.tw-gap-4 {
  gap: 1rem
}

.tw-gap-6 {
  gap: 1.5rem
}

.tw-gap-\[10px\] {
  gap: 10px
}

.tw-gap-\[12px\] {
  gap: 12px
}

.tw-gap-\[16px\] {
  gap: 16px
}

.tw-gap-\[18px\] {
  gap: 18px
}

.tw-gap-\[6px\] {
  gap: 6px
}

.tw-gap-\[8px\] {
  gap: 8px
}

.tw-gap-x-2 {
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem
}

.tw-gap-x-\[12px\] {
  -webkit-column-gap: 12px;
          column-gap: 12px
}

.tw-gap-x-\[20px\] {
  -webkit-column-gap: 20px;
          column-gap: 20px
}

.tw-gap-x-\[6px\] {
  -webkit-column-gap: 6px;
          column-gap: 6px
}

.tw-justify-self-end {
  justify-self: end
}

.tw-overflow-auto {
  overflow: auto
}

.tw-overflow-hidden {
  overflow: hidden
}

.tw-overflow-x-auto {
  overflow-x: auto
}

.tw-overflow-y-auto {
  overflow-y: auto
}

.tw-overflow-x-hidden {
  overflow-x: hidden
}

.tw-overscroll-contain {
  -ms-scroll-chaining: none;
      overscroll-behavior: contain
}

.tw-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tw-overflow-ellipsis {
  text-overflow: ellipsis
}

.tw-text-ellipsis {
  text-overflow: ellipsis
}

.tw-whitespace-nowrap {
  white-space: nowrap
}

.\!tw-rounded-lg {
  border-radius: 0.5rem !important
}

.tw-rounded {
  border-radius: 0.25rem
}

.tw-rounded-\[3px\] {
  border-radius: 3px
}

.tw-rounded-\[4px\] {
  border-radius: 4px
}

.tw-rounded-\[6px\] {
  border-radius: 6px
}

.tw-rounded-full {
  border-radius: 9999px
}

.tw-rounded-lg {
  border-radius: 0.5rem
}

.tw-rounded-md {
  border-radius: 0.375rem
}

.tw-rounded-xl {
  border-radius: 0.75rem
}

.tw-rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}

.tw-border {
  border-width: 1px
}

.tw-border-\[1px\] {
  border-width: 1px
}

.tw-border-\[2px\] {
  border-width: 2px
}

.tw-border-b {
  border-bottom-width: 1px
}

.tw-border-b-\[1px\] {
  border-bottom-width: 1px
}

.tw-border-l {
  border-left-width: 1px
}

.tw-border-r {
  border-right-width: 1px
}

.tw-border-t {
  border-top-width: 1px
}

.tw-border-solid {
  border-style: solid
}

.\!tw-border-none {
  border-style: none !important
}

.tw-border-none {
  border-style: none
}

.\!tw-border-\[\#0771ad\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(7 113 173 / var(--tw-border-opacity)) !important
}

.tw-border-\[\#0099FF\] {
  --tw-border-opacity: 1;
  border-color: rgb(0 153 255 / var(--tw-border-opacity))
}

.tw-border-\[\#0771ad\] {
  --tw-border-opacity: 1;
  border-color: rgb(7 113 173 / var(--tw-border-opacity))
}

.tw-border-\[\#1D7EE3\] {
  --tw-border-opacity: 1;
  border-color: rgb(29 126 227 / var(--tw-border-opacity))
}

.tw-border-\[\#3ac65d\] {
  --tw-border-opacity: 1;
  border-color: rgb(58 198 93 / var(--tw-border-opacity))
}

.tw-border-\[\#e3eef2\] {
  --tw-border-opacity: 1;
  border-color: rgb(227 238 242 / var(--tw-border-opacity))
}

.tw-border-\[\#e8ad90\] {
  --tw-border-opacity: 1;
  border-color: rgb(232 173 144 / var(--tw-border-opacity))
}

.tw-border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity))
}

.tw-border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.tw-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.tw-border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity))
}

.tw-border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity))
}

.tw-border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity))
}

.\!tw-bg-\[\#e3eef2\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(227 238 242 / var(--tw-bg-opacity)) !important
}

.\!tw-bg-gray-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity)) !important
}

.\!tw-bg-sky-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity)) !important
}

.\!tw-bg-transparent {
  background-color: transparent !important
}

.tw-bg-\[\#0099FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 153 255 / var(--tw-bg-opacity))
}

.tw-bg-\[\#1D7EE3\] {
  --tw-bg-opacity: 1;
  background-color: rgb(29 126 227 / var(--tw-bg-opacity))
}

.tw-bg-\[\#EEF8FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 248 255 / var(--tw-bg-opacity))
}

.tw-bg-\[\#b0f6ae\] {
  --tw-bg-opacity: 1;
  background-color: rgb(176 246 174 / var(--tw-bg-opacity))
}

.tw-bg-\[\#ccf1ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(204 241 255 / var(--tw-bg-opacity))
}

.tw-bg-\[\#f3f3f2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 243 242 / var(--tw-bg-opacity))
}

.tw-bg-\[\#f6d8c9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 216 201 / var(--tw-bg-opacity))
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.tw-bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity))
}

.tw-bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.tw-bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity))
}

.tw-bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity))
}

.tw-bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity))
}

.tw-bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity))
}

.tw-bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity))
}

.tw-bg-transparent {
  background-color: transparent
}

.tw-bg-violet-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 243 255 / var(--tw-bg-opacity))
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.tw-bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity))
}

.\!tw-p-0 {
  padding: 0px !important
}

.\!tw-p-5 {
  padding: 1.25rem !important
}

.tw-p-0 {
  padding: 0px
}

.tw-p-1 {
  padding: 0.25rem
}

.tw-p-2 {
  padding: 0.5rem
}

.tw-p-4 {
  padding: 1rem
}

.tw-p-5 {
  padding: 1.25rem
}

.tw-p-\[12px\] {
  padding: 12px
}

.tw-p-\[14px\] {
  padding: 14px
}

.tw-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.tw-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.tw-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.tw-px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.tw-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.tw-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.tw-px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px
}

.tw-px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px
}

.tw-px-\[14px\] {
  padding-left: 14px;
  padding-right: 14px
}

.tw-px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px
}

.tw-px-\[9px\] {
  padding-left: 9px;
  padding-right: 9px
}

.tw-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.tw-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.tw-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.tw-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.tw-py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px
}

.tw-py-\[12px\] {
  padding-top: 12px;
  padding-bottom: 12px
}

.tw-py-\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px
}

.tw-py-\[3px\] {
  padding-top: 3px;
  padding-bottom: 3px
}

.tw-py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px
}

.tw-py-\[9px\] {
  padding-top: 9px;
  padding-bottom: 9px
}

.\!tw-pt-3 {
  padding-top: 0.75rem !important
}

.tw-pb-4 {
  padding-bottom: 1rem
}

.tw-pb-\[12px\] {
  padding-bottom: 12px
}

.tw-pb-\[9px\] {
  padding-bottom: 9px
}

.tw-pe-2 {
  -webkit-padding-end: 0.5rem;
          padding-inline-end: 0.5rem
}

.tw-pe-3 {
  -webkit-padding-end: 0.75rem;
          padding-inline-end: 0.75rem
}

.tw-pe-4 {
  -webkit-padding-end: 1rem;
          padding-inline-end: 1rem
}

.tw-pe-\[10px\] {
  -webkit-padding-end: 10px;
          padding-inline-end: 10px
}

.tw-pe-\[12px\] {
  -webkit-padding-end: 12px;
          padding-inline-end: 12px
}

.tw-pe-\[14px\] {
  -webkit-padding-end: 14px;
          padding-inline-end: 14px
}

.tw-pe-\[22px\] {
  -webkit-padding-end: 22px;
          padding-inline-end: 22px
}

.tw-pe-\[250px\] {
  -webkit-padding-end: 250px;
          padding-inline-end: 250px
}

.tw-pe-\[34px\] {
  -webkit-padding-end: 34px;
          padding-inline-end: 34px
}

.tw-pe-\[8px\] {
  -webkit-padding-end: 8px;
          padding-inline-end: 8px
}

.tw-pl-4 {
  padding-left: 1rem
}

.tw-ps-2 {
  -webkit-padding-start: 0.5rem;
          padding-inline-start: 0.5rem
}

.tw-ps-3 {
  -webkit-padding-start: 0.75rem;
          padding-inline-start: 0.75rem
}

.tw-ps-4 {
  -webkit-padding-start: 1rem;
          padding-inline-start: 1rem
}

.tw-ps-6 {
  -webkit-padding-start: 1.5rem;
          padding-inline-start: 1.5rem
}

.tw-ps-7 {
  -webkit-padding-start: 1.75rem;
          padding-inline-start: 1.75rem
}

.tw-ps-\[12px\] {
  -webkit-padding-start: 12px;
          padding-inline-start: 12px
}

.tw-ps-\[36px\] {
  -webkit-padding-start: 36px;
          padding-inline-start: 36px
}

.tw-pt-2 {
  padding-top: 0.5rem
}

.tw-pt-3 {
  padding-top: 0.75rem
}

.tw-pt-\[12px\] {
  padding-top: 12px
}

.tw-pt-\[13px\] {
  padding-top: 13px
}

.tw-pt-\[18px\] {
  padding-top: 18px
}

.tw-pt-\[2px\] {
  padding-top: 2px
}

.tw-pt-\[8px\] {
  padding-top: 8px
}

.tw-pt-\[9px\] {
  padding-top: 9px
}

.tw-text-left {
  text-align: left
}

.\!tw-text-center {
  text-align: center !important
}

.tw-text-center {
  text-align: center
}

.tw-text-right {
  text-align: right
}

.tw-font-sans {
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.\!tw-text-\[16px\] {
  font-size: 16px !important
}

.\!tw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important
}

.\!tw-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important
}

.tw-text-\[10\.5px\] {
  font-size: 10.5px
}

.tw-text-\[10px\] {
  font-size: 10px
}

.tw-text-\[11px\] {
  font-size: 11px
}

.tw-text-\[12px\] {
  font-size: 12px
}

.tw-text-\[13\.5px\] {
  font-size: 13.5px
}

.tw-text-\[14px\] {
  font-size: 14px
}

.tw-text-\[15px\] {
  font-size: 15px
}

.tw-text-\[16px\] {
  font-size: 16px
}

.tw-text-\[17px\] {
  font-size: 17px
}

.tw-text-\[20px\] {
  font-size: 20px
}

.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.tw-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.tw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.tw-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.tw-text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.\!tw-font-medium {
  font-weight: 500 !important
}

.\!tw-font-normal {
  font-weight: 400 !important
}

.tw-font-bold {
  font-weight: 700
}

.tw-font-light {
  font-weight: 300
}

.tw-font-medium {
  font-weight: 500
}

.tw-font-normal {
  font-weight: 400
}

.tw-font-semibold {
  font-weight: 600
}

.tw-uppercase {
  text-transform: uppercase
}

.tw-capitalize {
  text-transform: capitalize
}

.tw-leading-7 {
  line-height: 1.75rem
}

.\!tw-text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important
}

.\!tw-text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important
}

.\!tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}

.tw-text-\[\#0099FF\] {
  --tw-text-opacity: 1;
  color: rgb(0 153 255 / var(--tw-text-opacity))
}

.tw-text-\[\#0099ff\] {
  --tw-text-opacity: 1;
  color: rgb(0 153 255 / var(--tw-text-opacity))
}

.tw-text-\[\#0771AD\] {
  --tw-text-opacity: 1;
  color: rgb(7 113 173 / var(--tw-text-opacity))
}

.tw-text-\[\#1D7EE3\] {
  --tw-text-opacity: 1;
  color: rgb(29 126 227 / var(--tw-text-opacity))
}

.tw-text-\[\#212529\] {
  --tw-text-opacity: 1;
  color: rgb(33 37 41 / var(--tw-text-opacity))
}

.tw-text-\[\#2185d0\] {
  --tw-text-opacity: 1;
  color: rgb(33 133 208 / var(--tw-text-opacity))
}

.tw-text-\[\#3C4B64\] {
  --tw-text-opacity: 1;
  color: rgb(60 75 100 / var(--tw-text-opacity))
}

.tw-text-\[\#808080\] {
  --tw-text-opacity: 1;
  color: rgb(128 128 128 / var(--tw-text-opacity))
}

.tw-text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity))
}

.tw-text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity))
}

.tw-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.tw-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.tw-text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.tw-text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

.tw-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity))
}

.tw-text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity))
}

.tw-text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity))
}

.tw-text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity))
}

.tw-text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity))
}

.tw-text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity))
}

.tw-text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity))
}

.tw-text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity))
}

.tw-text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity))
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.tw-text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity))
}

.tw-text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity))
}

.tw-opacity-35 {
  opacity: 0.35
}

.tw-opacity-45 {
  opacity: 0.45
}

.\!tw-shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.tw-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.tw-ring-green-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity))
}

.hover\:tw-bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}
.dte-fake-disabled {
  width: 13px;
  height: 13px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  background-color: #cccccc73;
  input[type="checkbox"] {
    display: none;
  }
}

.dte-partial-checked {
  input[type="checkbox"] {
    appearance: none; // <-- Ocultar checkbox por defecto.
    background-color: orange; //<-- Color de fondo del recuadro
    margin: 0;
    font: inherit;
    width: 13px;
    height: 13px;
    border-radius: 2px;
    transform: translateY(-0.075em);
    display: grid; // <-- Ayuda a centrar el ticket
    place-content: center; // <-- Ayuda a centrar el ticket.
  }

  /* Ticket interno */
  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em white; // Color del ticket.
    transform-origin: bottom left;
    clip-path: polygon(
      14% 44%,
      0 65%,
      50% 100%,
      100% 16%,
      80% 0%,
      43% 62%
    ); // Ticket en clip path
    transform: scale(1.15); // Tamaño del check
  }
}

.custom-information-partial-selection {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #e6e601;
  border-radius: 50%;
  cursor: pointer;
}

.custom-information-nomina {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: green;
  border-radius: 50%;
  cursor: pointer;
}

.edit-partial-amount {
  transition: 0.3s;
  background-color: transparent;
  &:hover {
    transition: 0.3s;
    padding: 3px;
    background-color: #ccccccab;
    cursor: pointer;
    border-radius: 5px;
  }
}

.edit-partial-amount-disabled {
  transition: 0.3s;
  background-color: transparent;
  &:hover {
    transition: 0.3s;
    padding: 3px;
    cursor: not-allowed;
    border-radius: 5px;
  }
}

.custom-partial-input {
  border: 1px solid #ccccccca;
  border-radius: 3px;
}

.doc-row-height {
  height: 30px;
}

.custom-provider-type-dropdown {
  width: 153px !important;
}

.custom-document-row {
  box-shadow: 4px 3px 4px rgba(46, 46, 66, 0.08);
  padding: 10px;
}

.custom-manual-process-titles {
  position: sticky;
  top: 0;
  background-color: white;
}

.custom-min-button-width {
  min-width: 140px !important;
}

.custom-documents-container-manual-process-manual {
  max-height: 400px;
  overflow: auto;
}

.modal {
  overflow: inherit !important;
}

.custom-disclaimer-manual-nomina {
  opacity: 0.5;
  color: #5b5b5b;
  font-family: "circular";
}

.custom-width-glosa-btn {
  width: 160px !important;
}

.custom-width-glosa-btn-sidebar {
  width: 130px !important;
}

.bg-inner-data-titles {
  background-color: #bbe3f1 !important;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.semantic-btn {
  border: 1px solid #2185d0 !important;
  background-color: transparent;
}

.normal-semantic-btn {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500 !important;
  color: white !important;
  height: 44px;
  width: 272px;
  padding: 12px 10px;
  border: 1px solid #2185d0 !important;
  background-color: #2185D0 !important;
}

.disabled-semantic-btn{
  font-size: 16px;
  line-height: 20px;
  font-weight: 500 !important;
  color: white !important;
  height: 44px;
  width: 272px;
  padding: 12px 10px;
  border: 1px solid #BEBEBE !important;
  background-color: #BEBEBE !important;
}
.normal-primary-nomina-color {
  color: #2185d0;
}

.animated-generate-nomina-btn {
  animation: Animation 20s ease infinite;
}

.custom-primary-nomina-color {
  color: #2185d0;
  display: flex;
  animation: colorChange 20s ease infinite;
}

@keyframes Animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 112, 244, 0.4);
    background-color: transparent
  }
  5% {
    box-shadow: 0 0 1px 10px rgba(0, 112, 244, 0.1);
  }
  10% {
    box-shadow: 0 0 0 0px rgba(0, 112, 244, 0.4);
    background-color: rgb(33, 133, 208)
  }
  15% {
    box-shadow: 0 0 1px 10px rgba(0, 112, 244, 0.1);
    background-color: transparent
  }
  20% {
    box-shadow: 0 0 0 0px rgba(0, 112, 244, 0.4);
  }
  25% {
    box-shadow: 0 0 1px 10px rgba(0, 112, 244, 0.1);
  }
  30% {
    box-shadow: 0 0 0 0px rgba(0, 112, 244, 0.4);
  }
  100% {
    box-shadow: 0 0 0 0px rgba(0, 112, 244, 0.4);
    background-color: transparent
  }
}

@keyframes colorChange {
  0%, 20%, 100% {
    color: rgba(33, 133, 208);
  }
  10% {
    color: rgb(255, 255, 255);
  }
}

.max-history-heigh {
  max-height: 50vh;
  overflow: auto;
}
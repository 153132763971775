@import "./variables";

.formInputHeader {
  width: 100%;
  font-size: 11pt;
  padding-bottom: 10px;
  font-weight: 400;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  color: black;
  opacity: .9;
}

.upperBorder {
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.ui.form .field>label {
  font-weight: 400 !important; 
  color: rgba(0,0,0, 0.7) !important;
  font-size: 0.8em !important;
  font-family: "Readex Pro", Arial, Helvetica, sans-serif !important;
}

.ui.selection.dropdown {
  min-height: 2.5em !important;
  //padding: .7em 2.1em .7em 1em !important;
}

.shortDropdown .ui.selection.dropdown .menu {
  max-height: 6.5rem !important;
}

.ui.multiple.dropdown>.label {
  padding: 0.3em .5em !important;
  font-size: 0.8em !important;
}

.ui.form .inline.fields .field {
  padding: 0 0 0 0;
}

.ui.form .inline.field>.selection.dropdown, .ui.form .inline.fields .field>.selection.dropdown {
  width: 100% !important;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  opacity: 0.95 !important;
}

// .ui.form .inline.field:not(.wide) .ui.input, .ui.form .inline.fields .field:not(.wide) .ui.input {
//   margin-bottom: 8px !important;
// }

.ui.checkbox label, .ui.checkbox+label {
  color: rgba(0,0,0,.5) !important;
}

// .ui.blue.button {
//   border-radius: 2em !important;
// }

// .ui.black.button {
//   border-radius: 2em !important;
// }

.settingIconColor > .ui.icon.input>i.icon:not(.link){
  color: green;
}
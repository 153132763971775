//Constants
$barRightWidth: 90%;
$barRightExtends: 40vw;

//Font Color
$font-white: #ffffff;
$font-white-dark: #e0e2e4;
$font-dark-medium: #5b5b5b;
$font-dark: #000000;
$font-dark-light: #131313;
$font-blue: #0099ff;
$font-blue-light: #837ded;
$font-blue-light-plus: #8779f5;
$font-blue-light-less: #8765d6;
$font-blue-dark: #2b3a48;
$font-green: #3ac65d;
$font-green-light: #49c788;
$font-green-light-plus: #81ebba;
$font-red: #ff0000;
$font-red-light: #ff6666;
$font-grey: #979797;
$font-purple: #800080;
$font-purple-light: #9869ec;
$font-purple-light-pluss: #8a9df9;
$font-info: #ececd8;
$font-black: #000000;
$font-blue-semantic: #2185d0;

//Border Color
$border-purple-light: #9f98dd;
$border-light: #eee;
$border-royal-blue: #4169e1;
$border-royal-blue-dark: #2b4697;
$border-blue: #0099ff;
$border-blue-semantic: #2185d0;
$border-blue-selected: #148ae4;
$border-navBar-selected: #03c0f3;
$border-blue-light: #837ded;
$border-blue-light-plus: #8765d6;
$border-green-light: #80dbba;
$border-green-light-plus: #81ebba;
$border-dark-light: #0000004d;
$border-dark: #000000;
$border-grey-light: #d8dbe0;
$border-grey-light-opacity: #80808078;
$border-grey: #c1c1c1;
$border-red: #ff6666;
$border-orange-strong-opacity: #ff51008a;
$border-orange-inform: #e6a71f;

//Backgroun Color
$background-white: #ffffff;
$background-white-light: #e1e4f9;
$background-white-cacke: #f4f4f8;
$background-grey-light: #f5f4fe;
$background-grey-light-plus: #f2f2f2;
$background-grey-ultra-light: #d0d5eb;
$background-grey-dark: #e1e7f9;
$background-grey-dark-plus: #636f83;
$background-blue-light: #8779f5;
$background-blue-light-less: #5444d4;
$background-blue-royal: #4169e1;
$background-blue-royal-dark: #2f4ca3;
$background-blue-light-plus: #3e3eff;
$background-purple: #800080;
$background-purple-light-plus: #8779f5;
$background-purple-light: #a500a5;
$background-purple-dark-light: #913ff2;
$background-purple-dark: #3f063f;
$background-green: #7cee7c;
$background-green-light: #7cdab8;
$background-green-dark: #62ad92;
$background-green-light-plus: #81ebba;
$background-green-dark: #62ad92;
$background-blue-dark: #2b3a48;
$background-blue-dark-less: #2f3a47;
$background-red: #ec8a90;
$background-red-strong: #ff0000;
$background-red-light: #e5555f;
$background-brown-strong-opacity: #ff7d4127;
$background-dark: #00000099;
$background-black: #000000;
$background-black-opacity: #0000006e;
$background-dark-light: #a3a3a5;
$background-grey-light-opacity: #00000078;
$background-orange-inform: #e6a71f;
//$background-navBar-selected: #186ea8; Viejo Color
$background-navBar-selected: #03c0f3;
//$background-navBar-hover: #73b1db; Viejo Color
$background-navBar-hover: #03c0f380;
$background-navBar: #0099ff;
$background-initial-balance-amount: #c4e7ff;
$background-modal: #000000d9;

//Box Shadow
$box-shadow-dark-light: #00000040;
$box-shadow-blue-light: #8779f5;

//Animation CardPreLoader
$background-animation-dark: #ededed;
$background-animation-dark-plus: #c0b9b9;
$background-animation-dark-less: #e2e2e2;
$border-animation-blue-light-plus: #0099ff;
$border-animation-green-light-plus: #166498;

//Scrollbar
$scroll-grey-light: #babac0;
$scroll-hover: #939397;
$border-scroll-light: #f5f5f5;

//Other
$selected-row: #ededed;
.custom-providers-table {
    height: 300px;
    overflow-y: auto;

    &__selector {
        transition: .3s;
        border: 1px solid #CCCCCC;
        border-radius: 10px;
        padding: 20px;
        height: 60px;
        overflow: hidden;
    }
}
// If you want to override variables do it here
@import "variables";
@import "modal-nomina";
@import "nomina";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

.headerBar {
  background-image: url("../assets/images/Fondo.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 10vh;
}

.pepperApp {
  background-color: white;
  height: 100vh;
}

/* .bg-image {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
} */

.vh-90 {
  height: 90vh;
}

.w-95 {
  width: 95%;
}

.w-90 {
  width: 90%;
}

.w-85 {
  width: 85%;
}

.w-80 {
  width: 80%;
}

.w-40 {
  width: 40% !important;
}

.w-5 {
  width: 5% !important;
}

.fs-16 {
  font-size: 16px;
}

/* @media (min-width: 1201px) {
  .bg-image {
    //background-image: url("../assets/images/Fondo.svg");
    background-color: white;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (max-width: 1200px) and (min-width: 981px) {
  .bg-image {
   // background-image: url("../assets/images/Fondo.svg");
   background-color: white;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (max-width: 980px) and (min-width: 5px) {
  .bg-image {
   // background-image: url("../assets/images/Fondo.svg");
   background-color: white;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
} */

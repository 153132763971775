.toastContainer {
    position: absolute;
    top: 0;
    right: 60px;
    margin-top: 70px;
    min-width: 50px;
    transform: translateX(calc(100% + 60px));
    transition: all 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.35);
    box-shadow: 0 5px 10px rgba(0,0,0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    z-index: 1000;
}

.toastContainerActive {
  transform: translateX(0%);
}

@media (pointer:none), (pointer:coarse) {
  .toastContainer {
    display: none;
  }

  .toastContainerActive {
    display: block;
  }
}

.toastBox {
  display: flex;
  padding: 15px 50px 15px 20px;    
  align-items: center;
  background-color: #fff;
  min-width: 400px;
}

.toastBox .toastCheck {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.3em;
    border-radius: 50%;
    margin: 0 15px 0 0;
}

.toastCheckSuccess {
  background-color: white;
  color: green;
}

.toastCheckError {
  background-color: white;
  color: red;
}

.toastCheckLoading {
  background-color: white;
  color: blue;
}

.toastCheckWarning {
  background-color: white;
  color: orange;
}

.toastClose {
    position: absolute;
    top: 10px;
    right: 10px;
    margin-right: 15px;
    font-size: 12px;
    opacity: 0.6 !important;
    cursor: pointer;
}

.toastProgress {
    position: absolute;
    background-color: green;
    width: 0%;
    right: 0;
    bottom: 0;
    height: 2px;
    border-radius: 12px;
}

.toastProgressActive {
  animation: showProgress linear forwards;
}

@keyframes showProgress {
    100% {
        width: 100%;
    }
}

.toastUpper {
    font-weight: 600;
    font-size: 15px;
}

.toastUpper > span {
    padding-top: 3px;
}

.toastUpper .toastTime {
    opacity: 0.6;
    font-size: 11px;
    padding-top: 5px;
    padding-left: 5px;
}

.toastUpper > img {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.toastLower {
    padding-top: 2px;
    font-size: 14px;
}

.Toastify__toast-container {
  min-width: 420px !important;
  max-width: 600px !important;
}
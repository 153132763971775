@import "../../scss/variables";

.modalForm {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto !important;
  width: 100vw;
  z-index: 1032;
  position: fixed;
  background-color: $background-modal;
  animation: opacityMove 0.5s linear;

  &__content {
    background-color: $background-white;
    border-radius: 5px;
    width: 450px;

    &__title {
      font-size: 15pt;
      color: $font-dark-light;
      border-bottom: 1px solid #22242626;
      padding: 10px;
      text-align: center;
    }

    &__body {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12pt;
      font-weight: bold;
      padding: 10px 10px;
      color: $font-dark-light;

      &__dynamicInput {
        display: grid;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin: 0 auto;
      }
    }

    &__subBody {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10pt;
      color: $font-dark-light;
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 15px;
    }

    &__actionButtons {
      display: flex;
    }

    &__deleteBtn {
      margin: 0 auto 0 0 !important;
    }
  }
}

@keyframes showAlert {
  from {
    height: 0px;
  }
  to {
    height: 50px;
  }
}

@keyframes opacityMove {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.requiredFieldName {
  color: $font-red;
}
